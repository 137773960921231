<template>
  <div>
    <nurse-table
      :canDelete="true"
      viewRoute="FranchiseNurseDetails"
      :canCreate="true"
      createRoute="FranchiseNurseCreate"
      viewDetailRoute="FranchiseNurseDetailView"
      :canAssignClinic="true"
    />
  </div>
</template>

<script>
import nurseTable from "../../components/nurses/nurseTable.vue";

export default {
  components: {
    nurseTable
  },
  data: () => ({})
};
</script>